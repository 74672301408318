import React, { useMemo } from 'react'

import Select from '@/components/Select'

import { useGetMe } from '@/auth/queries'
import { getDayName } from '@/utils'
import { Language } from '@/i18n'

export interface DaysOfWeekSelectorProps {
  value?: number[]
  title: string
  label: string
  onChange: (value?: number[]) => void
}

export const DaysOfWeekSelector: React.FC<DaysOfWeekSelectorProps> = ({ value, title, label, onChange }) => {
  const me = useGetMe()

  const DAYS_OPTIONS = useMemo(
    () => Array.from({ length: 7 }, (_, i) => ({ value: i, label: getDayName(i, me.data.language_code as Language) })),
    [me.data.language_code],
  )

  const selectedPlaceholder = useMemo(() => {
    return value?.length
      ? value
          ?.sort()
          .map((day) => getDayName(day, me.data.language_code as Language, 'short'))
          .join(', ')
      : ''
  }, [value, me.data.language_code])

  return (
    <Select
      options={DAYS_OPTIONS}
      isMulti
      title={title}
      label={label}
      value={value}
      onChange={onChange}
      isMultipleSelectHightlight={false}
      placeholder={selectedPlaceholder}
    />
  )
}

export default React.memo(DaysOfWeekSelector)
