import * as Sentry from '@sentry/react'
import { Language } from '@/i18n'

export const dataURLtoBlob = async (dataURL: string) => {
  const response = await fetch(dataURL)
  return await response.blob()
}
export const debounce = <Params extends unknown[], Result = unknown>(
  func: (...args: Params) => Promise<Result> | void,
  timeout = 300,
): ((...args: Params) => Promise<Result>) => {
  let timer: NodeJS.Timeout
  return (...args: Params) =>
    new Promise((resolve) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const result = func(...args)
        if (result) {
          resolve(result)
        }
      }, timeout)
    })
}

const shortEnDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const longEnDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const narrowEnDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

export const getDayName = (dayNumber: number, locale = Language.EN, format: 'long' | 'short' | 'narrow' = 'long') => {
  try {
    const date = new Date(Date.UTC(2021, 0, 4 + dayNumber)) // 2021-01-03 is a Monday

    const localeOption = Object.values(Language).includes(locale) ? locale : Language.EN
    let dayName = new Intl.DateTimeFormat(localeOption, { weekday: format }).format(date)
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1)

    return dayName
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    if (format === 'short') {
      return shortEnDays[dayNumber]
    }
    if (format === 'long') {
      return longEnDays[dayNumber]
    }
    if (format === 'narrow') {
      return narrowEnDays[dayNumber]
    }
    return ''
  }
}

export const leftPadTime = (str: string | number) => {
  const value = typeof str === 'string' ? str : str.toString()
  return value.length < 2 ? '0' + value : value
}
