import {
  createBrowserRouter,
  generatePath,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useThemeMode } from 'flowbite-react'
import { ToastContainer } from 'react-toastify'
import { swipeBehavior, miniApp, useLaunchParams, viewport, useSignal } from '@telegram-apps/sdk-react'
import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'

import Layout from './components/Layout'
import Home from './home/pages/Home'
import Project from './projects/pages/Project'
import Board from './projects/pages/Board'
import ProjectSettings from './projects/pages/Settings'
import ProjectStatuses from './projects/pages/Statuses'
import ProjectUsers from './projects/pages/Users'
import Backlog from './projects/pages/Backlog'
import TicketLayout from './projects/pages/Ticket/components/Layout'
import Ticket from './projects/pages/Ticket/components/TicketContent'
import Comments from './projects/pages/Ticket/components/Comments'
import RecurringTickets from './projects/pages/Recurring'
import NewTicket from './projects/pages/NewTicket'
import Welcome from './teamspace/pages/Welcome'
import Settings from './settings/pages/Settings'
import ErrorBoundaryComponent from './components/ErrorBoundary'

import './i18n'
import routes from './utils/routes'
import { useAuth } from './auth/queries'
import { useGetAllProjects } from './projects/queries'
import useAmplitude, { sendEvent } from './utils/hooks/useAmplitude'
import { useIsIpad } from './utils/hooks/useIsMobileDevice'

import { project, startParamHandled as startParamHandledAtom } from './projects/store'

import 'react-toastify/dist/ReactToastify.css'
import 'react-calendar/dist/Calendar.css'

import './index.css'

const getStartPathIsValid = (path: string) => {
  if (!path.includes('teamspace_id') && !path.includes('project_id')) {
    return true
  }

  return false
}

const NavigationLayout = () => {
  const { teamspaceId: teamspaceIdParams, projectId: projectIdParams } = useParams<{
    teamspaceId: string
    projectId: string
  }>()
  const navigate = useNavigate()
  const { data: projects } = useGetAllProjects()
  const launchParams = useLaunchParams()

  const [projectIdStore, setProjectIdStore] = useAtom(project)
  const [startParamHandled, setStartParamHandled] = useAtom(startParamHandledAtom)

  const checkAndReturnValidProjectId = () => {
    if (projectIdParams) {
      const paramsValuePresentedInArray = projects.some((project) => project.uuid === projectIdParams)
      return paramsValuePresentedInArray ? projectIdParams : projects[0]?.uuid
    }
    if (projectIdStore) {
      const storedValuePresentedInArray = projects.some((project) => project.uuid === projectIdStore)
      return storedValuePresentedInArray ? projectIdStore : projects[0]?.uuid
    }

    return projects[0]?.uuid
  }

  const projectId = checkAndReturnValidProjectId()
  const teamspaceId = projects.find((project) => project.uuid === projectId)?.teamspace_id

  useEffect(() => {
    setStartParamHandled(true)
    if (launchParams.startParam && !startParamHandled) {
      try {
        const path = atob(launchParams.startParam)

        if (getStartPathIsValid(path)) {
          navigate(path)
        }
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
      }
    }
  }, [startParamHandled])

  useEffect(() => {
    if (projectId) {
      setProjectIdStore(projectId)
    }
  }, [projectId])

  if (!projects.length) {
    return <Navigate to="/" />
  }

  if (!teamspaceIdParams) {
    return <Navigate to={generatePath(routes.private.home, { teamspaceId, projectId })} />
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
      <Outlet />
    </ErrorBoundary>
  )
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
        <Welcome />
      </ErrorBoundary>
    ),
  },
  {
    element: <NavigationLayout />,
    children: [
      {
        element: <TicketLayout />,
        children: [
          {
            path: routes.private.ticket,
            element: <Ticket />,
          },
          {
            path: routes.private.ticketComments,
            element: <Comments />,
          },
        ],
      },
      {
        path: routes.private.newTicket,
        element: <NewTicket />,
      },
      {
        element: <Layout />,
        children: [
          {
            path: routes.private.home,
            element: <Home />,
          },
          {
            path: routes.private.settings,
            element: <Settings />,
          },

          {
            path: routes.private.projects,
            element: <Project />,
          },
          {
            path: routes.private.projectById,
            element: <Project />,
            children: [
              { path: routes.private.projectById, element: <Board /> },
              {
                path: routes.private.board,
                element: <Board />,
              },
              {
                path: routes.private.projectBacklog,
                element: <Backlog />,
              },
              {
                path: routes.private.projectSettings,
                element: <ProjectSettings />,
              },
              {
                path: routes.private.projectColumns,
                element: <ProjectStatuses />,
              },
              {
                path: routes.private.projectUsers,
                element: <ProjectUsers />,
              },
              {
                path: routes.private.recurring,
                element: <RecurringTickets />,
              },
            ],
          },
        ],
      },
    ],
  },
])

function App() {
  const { i18n } = useTranslation()
  const { setMode } = useThemeMode()
  const height = useSignal(viewport.height)

  const launchParams = useLaunchParams()
  const isIpad = useIsIpad()
  const isDark = useSignal(miniApp.isDark)

  useEffect(() => {
    setMode(isDark ? 'dark' : 'light')
  }, [])

  const viewportMounted = useSignal(viewport.isMounted)

  const { data: authData } = useAuth(launchParams.initDataRaw || '')
  useAmplitude()

  useEffect(() => {
    if (authData?.user.language_code) {
      i18n.changeLanguage(authData.user.language_code)
    }
  }, [authData.user])

  useEffect(() => {
    try {
      miniApp.setHeaderColor.ifAvailable(isDark ? '#1f2937' : '#ffffff')
      miniApp.setBackgroundColor.ifAvailable(isDark ? '#1f2937' : '#EBEAE9')
      miniApp.setBottomBarColor.ifAvailable(isDark ? '#1f2937' : '#EBEAE9')
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [isDark])

  useEffect(() => {
    if (viewportMounted) {
      const vh = (height + (isIpad ? 12 : 0)) * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }, [viewportMounted, height])

  useEffect(() => {
    try {
      swipeBehavior.disableVertical.ifAvailable()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [])

  useEffect(() => {
    try {
      document.getElementById('loader')?.classList?.add?.('hidden')
      sendEvent('launch-app')
      const handleFocusIn = (event: FocusEvent) => {
        const target = event?.target as HTMLElement
        if (target?.tagName === 'INPUT' || target?.tagName === 'TEXTAREA') {
          document.getElementById('root')?.classList.add('touch-none')
        }
      }

      const handleFocusOut = (event: FocusEvent) => {
        const target = event?.target as HTMLElement
        if (target?.tagName === 'INPUT' || target?.tagName === 'TEXTAREA') {
          document.getElementById('root')?.classList.remove('touch-none')
        }
      }
      document.addEventListener('focusin', handleFocusIn)
      document.addEventListener('focusout', handleFocusOut)

      return () => {
        document.removeEventListener('focusin', handleFocusIn)
        document.removeEventListener('focusout', handleFocusOut)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [])

  return (
    <div className="h-dinamic select-none bg-wall-main-bg-light text-neutral-700 transition-[height] dark:bg-wall-main-bg-dark dark:text-neutral-200">
      <RouterProvider router={router} />
      <ToastContainer theme={isDark ? 'dark' : 'light'} className="text-sm" toastClassName="font-sans" />
    </div>
  )
}

export default App
