import { generatePath, Navigate, useNavigate } from 'react-router-dom'
import { Button, TextInput } from 'flowbite-react'
import { initData, useSignal } from '@telegram-apps/sdk-react'
import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import Logo from '@/components/Logo'

import { useCreateProject, useGetAllProjects } from '@/projects/queries'
import { useCreateTeamspace, useGetTeamspaces } from '@/teamspace/queries'
import routes from '@/utils/routes'
import { useGetMe } from '@/auth/queries'
import { useFunnyNameGenerator } from '@/utils/hooks/useFunnyNameGenerator'
import { project } from '@/projects/store'
import { useRef } from 'react'

export interface WelcomeProps {}

type Inputs = {
  name: string
}

export const Welcome: React.FC<WelcomeProps> = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'welcome',
  })
  const { data: user } = useGetMe()
  const telegramUser = useSignal(initData?.user)
  const navigate = useNavigate()
  const [selectedProject, setSelectedProject] = useAtom(project)

  const isCreateFirstRef = useRef<boolean>()

  const { name: projectName } = useFunnyNameGenerator()

  const { data: teamspaces } = useGetTeamspaces()
  const { data: projects, refetch, isFetching } = useGetAllProjects()

  const myTeamspace = teamspaces.find((ts) => ts.owner_id === user.uuid)

  const { mutateAsync: createTeamspace, isPending: isPendingTeamspace } = useCreateTeamspace()
  const { mutateAsync: createProject, isPending: isPendingProject } = useCreateProject(myTeamspace?.uuid, false)

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      name: projectName.charAt(0).toUpperCase() + projectName.slice(1),
    },
  })

  const onSubmit = async (data: Inputs) => {
    isCreateFirstRef.current = true
    let teamspaceUUID = myTeamspace?.uuid
    if (!teamspaceUUID) {
      const { uuid } = await createTeamspace({
        name: [telegramUser?.firstName, telegramUser?.lastName].filter(Boolean).join(' '),
      })
      teamspaceUUID = uuid
    }
    const key = data.name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
    const project = await createProject({ name: data.name, teamspace_id: teamspaceUUID, key })
    await refetch()
    setSelectedProject(project.uuid)
    navigate(generatePath(routes.private.projectById, { teamspaceId: teamspaceUUID, projectId: project.uuid }))
  }

  if (projects.length && !isCreateFirstRef.current) {
    const project = projects.find((project) => project.uuid === selectedProject) || projects[0]

    return (
      <Navigate
        to={generatePath(routes.private.home, {
          teamspaceId: project.teamspace_id,
          projectId: project.uuid,
        })}
      />
    )
  }
  const isLoading = isPendingTeamspace || isPendingProject || isFetching
  return (
    <form
      className="flex h-dinamic flex-col items-center justify-center gap-3 bg-wall-main-bg-light p-3 text-center dark:bg-wall-main-bg-dark"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Logo />
      <h2>{t('title')}</h2>
      <div className="text-sm">
        <p>{t('getStartedMessage')}</p>
        <p>{t('suggestionMessage')}</p>
      </div>
      <TextInput placeholder="" {...register('name')} />
      <div className="flex">
        <Button
          size="xs"
          type="submit"
          data-amplitude="create-first-project"
          isProcessing={isLoading}
          disabled={isLoading}
        >
          {t('proceedLabel')}
        </Button>
      </div>
    </form>
  )
}

export default Welcome
